import { ErrorMessage } from "@hookform/error-message";
import { ReactNode } from "react";
import { useFormState } from "react-hook-form";

function FormFieldError({ message }) {
  return <p className="ml-1 font-content text-sm text-sv-red">{message}</p>;
}

function FormFieldLabel({ name, label }) {
  return (
    <label className="font-content text-base text-very-gray" htmlFor={name}>
      {label}
    </label>
  );
}

export interface FormFieldWrapperProps {
  name: string;
  label: string;
  children: ReactNode;
}

export function FormFieldWrapper(props: FormFieldWrapperProps) {
  const formState = useFormState();

  return (
    <div>
      <div className="mb-2">
        <FormFieldLabel name={props.name} label={props.label} />
      </div>
      {props.children}
      <ErrorMessage
        name={props.name}
        errors={formState.errors}
        render={(props) => (
          <div className="mt-2 flex items-center">
            <i
              className="fa-duotone fa-triangle-exclamation"
              style={
                { "--fa-secondary-color": "var(--yellow-pale)" } as unknown
              }
            ></i>
            <FormFieldError {...props} />
          </div>
        )}
      />
    </div>
  );
}

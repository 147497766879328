import { ReactNode } from "react";
import { useFormState } from "react-hook-form";

import classnames from "lib/classnames";

import { BackgroundColors, TextColors } from "types/theme.types";

interface Props {
  children: ReactNode;
  color?: TextColors;
  bgColor?: BackgroundColors;
  textSize?: string;
}

export default function SubmitButton(props: Props) {
  const formState = useFormState();
  const isDisabled = formState.isSubmitting;

  const submitButtonClassNames = classnames.submit(
    isDisabled,
    props.color,
    props.bgColor,
    props.textSize
  );

  return (
    <button
      type="submit"
      disabled={isDisabled}
      className={submitButtonClassNames}
    >
      {props.children}
    </button>
  );
}

import { useFormContext } from "react-hook-form";

import { FormFieldWrapper } from "components/atoms/FormFieldWrapper";

import classnames from "lib/classnames";

const FIELD_NAME = "usa_work_authorization";

const GREEN_CARD_OPTIONS = [
  {
    text: "",
    value: "",
  },
  {
    text: "Yes, I am legally authorized to work in the US",
    value: "citizen_or_green_card_holder",
  },
  {
    text: "No, I’m not legally authorized to work in the US",
    value: "not_citizen_nor_green_card_holder",
  },
];

export default function USCitizenInput() {
  const formContext = useFormContext();

  const usCitizenRegistration = formContext?.register(FIELD_NAME, {
    required: {
      value: true,
      message: "This field is required",
    },
  });

  const usCitizenInputClassNames = classnames.selectBox(
    !!formContext.formState.errors[FIELD_NAME]
  );

  return (
    <FormFieldWrapper
      name={FIELD_NAME}
      label="Are you legally authorized to work in the US?"
    >
      <select
        id={FIELD_NAME}
        className={usCitizenInputClassNames}
        {...usCitizenRegistration}
      >
        {GREEN_CARD_OPTIONS.map((gcOption) => (
          <option key={gcOption.value} value={gcOption.value}>
            {gcOption.text}
          </option>
        ))}
      </select>
    </FormFieldWrapper>
  );
}

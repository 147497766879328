import * as Sentry from "@sentry/nextjs";
import thru from "lodash/thru";
import { useRouter } from "next/router";
import { ReactNode, useEffect, useState } from "react";
import {
  FieldValues,
  FormProvider,
  SubmitHandler,
  useForm,
} from "react-hook-form";

import FormErrorBadge from "components/atoms/FormErrorBadge/FormErrorBadge";
import Typography from "components/atoms/Typography";

import errorMessages from "config/form-error-messages";

import { generateApplicationData, submitApplication } from "lib/services";
import * as tracker from "lib/tracker";
import { convertReferral } from "lib/tracker/referral-rock";
import { getLastWebVisitUrl, getWebVisitUrl } from "lib/tracker/web-visit";
import {
  getApplicationIdFromQuery,
  getQueryFromRouterPath,
  getQueryParameterAtIndex,
  getUTMSFromStorage,
  snakeCaseObject,
} from "lib/utils";
import allocateServerExceptionsToFormFields from "lib/validations/allocate-server-exceptions-to-form-fields";

import { ApplicationFlows, ApplyResponse } from "types/apply.types";
import { ServerException } from "types/common.types";

interface Props {
  children: ReactNode;
  leadContext?: string;
  step: number;
  // Determines if the application is completed. Only true in the last step.
  //Depending on the experiments running, it can be true multiple times if the experiments add extra steps for certain users
  isApplicationComplete: boolean;
  nextStepRedirectPath: string;
  stepName: string;
  channelsForTrackingMerge?: tracker.ChannelsForTracking;
  flow?: ApplicationFlows;
  // allows to extend default form validation
  validateSubmit?: (data: FieldValues) => boolean;
  continueApplicationLink?: string;
}

function FormContainer(props: Props) {
  const router = useRouter();
  const query = getQueryFromRouterPath(router);
  const uuid = getApplicationIdFromQuery(query);
  const isLeadStart = props.step === 1;
  const isApplicationStart = props.step === 2;
  const applicationId = uuid ?? "";

  const [submitError, setSubmitError] = useState<string | null>();

  const formMethods = useForm({ mode: "all" });

  const children = Array.isArray(props.children)
    ? props.children
    : [props.children];

  useEffect(() => {
    if (isApplicationStart) {
      tracker.applicationStarted(props.channelsForTrackingMerge, props.flow);
    }

    if (isLeadStart) {
      tracker.leadStarted(props.channelsForTrackingMerge, props.flow);
    } else {
      tracker.applyStepStarted(
        applicationId,
        props.step,
        props.stepName,
        props.channelsForTrackingMerge,
        props.flow
      );
    }
  }, [uuid]);

  const onSubmit: SubmitHandler<FieldValues> = async (fieldsData, event) => {
    event.preventDefault();

    if (props.validateSubmit?.(fieldsData) === false) {
      return;
    }

    const data = generateApplicationData({
      ...fieldsData,
      isApplicationComplete: props.isApplicationComplete,
      applicationStep: props.step,
      nextStepRedirectPath: props.nextStepRedirectPath,
      continueApplicationLink: props.continueApplicationLink,
      landingPageSource: getQueryParameterAtIndex(query, "lps"),
      leadContext: props.leadContext,
      applicationFlow: props.flow,
    });

    const payload = thru(
      {
        data,
        utmData: getUTMSFromStorage("first"),
        lastUtmData: getUTMSFromStorage("last"),
        pixelData: tracker.getFacebookCookies(),
        googleData: {
          google_client_id: await tracker.getGoogleClientId(),
          google_click_id: tracker.getGclid(),
        },
        cookieData:
          props.step === 1
            ? {
                first_web_visit_url: getWebVisitUrl(),
                last_web_visit_url: getLastWebVisitUrl(),
              }
            : null,
      },
      snakeCaseObject
    );

    setSubmitError(null);

    const response = await submitApplication({
      uuid,
      payload,
    });

    if (!response.ok) {
      if (response.status >= 500) {
        Sentry.captureMessage("Server error on application process", {
          extra: { response },
        });
        setSubmitError(errorMessages.serverError);
      } else if (response.status >= 400) {
        Sentry.captureMessage("Client error on application process", {
          extra: { response },
        });
        setSubmitError(errorMessages.valuesError);
      }
      const serverExceptions = (await response.json()) as ServerException;
      allocateServerExceptionsToFormFields(formMethods, serverExceptions);
    } else {
      const result = (await response.json()) as ApplyResponse;
      const nextStep = result.next_step_redirect_path;

      if (fieldsData.cohortNumber >= 0) {
        tracker.cohortChoice(fieldsData.cohortNumber);
      }

      if (isLeadStart) {
        tracker.leadCompleted(
          true,
          "Unified Apply Form",
          props.channelsForTrackingMerge,
          result.user_id,
          props.flow
        );
        if (props.leadContext === "Brochure Form") {
          tracker.brochureRequest();
        }
      } else {
        tracker.applyStepCompleted(
          applicationId,
          props.step,
          props.stepName,
          props.channelsForTrackingMerge,
          props.flow
        );
      }

      if (result.processed) {
        tracker.applicationCompleted(
          result.is_qualified,
          "Unified Apply Form",
          result.id,
          props.channelsForTrackingMerge
        );
      }

      tracker.identifyUser(
        result.user_id,
        undefined,
        props.channelsForTrackingMerge
      );

      if (result.processed) {
        tracker.applyResult(
          result.id,
          result.is_qualified,
          nextStep,
          props.channelsForTrackingMerge,
          props.step,
          props.stepName,
          props.flow
        );
      }

      if (props.step === 1) {
        const [_, query] = nextStep.split("?");
        const params = new URLSearchParams(query);
        const applicationId = params.get("id");
        convertReferral({
          firstName: data.first_name,
          lastName: data.last_name,
          email: data.email,
          externalIdentifier: applicationId,
        });
      }

      await router.push(nextStep);
    }
  };

  return (
    <FormProvider {...formMethods}>
      <form onSubmit={formMethods.handleSubmit(onSubmit)}>{children}</form>
      {submitError && (
        <FormErrorBadge>
          <Typography.Body>{submitError}</Typography.Body>
        </FormErrorBadge>
      )}
    </FormProvider>
  );
}

export default FormContainer;

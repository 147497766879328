import { Controller, useFormContext } from "react-hook-form";
import { IMaskInput } from "react-imask";

import { FormFieldWrapper } from "components/atoms/FormFieldWrapper";

import classnames from "lib/classnames";

const FIELD_NAME = "phone";

export default function PhoneInput() {
  const formContext = useFormContext();

  const phoneInputClassNames = classnames.textField(
    !!formContext.formState.errors[FIELD_NAME]
  );

  return (
    <FormFieldWrapper name={FIELD_NAME} label="Phone number">
      <Controller
        control={formContext.control}
        name={FIELD_NAME}
        rules={{
          required: { value: true, message: "This field is required" },
        }}
        render={({ field: { value, onChange, onBlur } }) => (
          <IMaskInput
            mask="+1 (000) 000-0000"
            value={value}
            onAccept={(value) => onChange(value)}
            onBlur={onBlur}
            className={phoneInputClassNames}
            type="tel"
            unmask
          />
        )}
      />
    </FormFieldWrapper>
  );
}

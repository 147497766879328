import { useFormContext } from "react-hook-form";

import { FormFieldWrapper } from "components/atoms/FormFieldWrapper";

import classnames from "lib/classnames";

const FIELD_NAME = "first_name";

export default function FirstNameInput() {
  const formContext = useFormContext();

  const firstNameRegistration = formContext?.register(FIELD_NAME, {
    required: {
      value: true,
      message: "This field is required",
    },
  });

  const firstNameInputClassNames = classnames.textField(
    !!formContext.formState.errors[FIELD_NAME]
  );

  return (
    <FormFieldWrapper name={FIELD_NAME} label="First Name">
      <input
        id={FIELD_NAME}
        type="text"
        className={firstNameInputClassNames}
        {...firstNameRegistration}
      />
    </FormFieldWrapper>
  );
}

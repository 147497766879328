import { ReactNode } from "react";

interface Props {
  children?: ReactNode;
}

export default function FormErrorBadge(props: Props) {
  return (
    <div className="bg-red-100 rounded text-sm tablet:text-base text-orange-dark mt-5 px-4 py-5">
      {props.children}
    </div>
  );
}

import { UseFormReturn } from "react-hook-form";

import { ServerException } from "types/common.types";

export default function allocateServerExceptionsToFormFields(
  formMethods: UseFormReturn,
  exceptions: ServerException
) {
  exceptions?.detail?.forEach((error) => {
    const fieldName = error.loc[2];
    formMethods.setError(fieldName, {
      type: "validate",
      message: error.msg,
    });
  });
}

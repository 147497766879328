export interface ConversionParams {
  firstName: string;
  lastName: string;
  email: string;
  externalIdentifier?: string;
  amount?: number;
}

export function convertReferral(params: ConversionParams): void {
  window["referralJS"].conversion = {
    debug:
      process.env.NEXT_PUBLIC_VERCEL_ENV === "production" ? "false" : "true",
    parameters: params,
  };
}

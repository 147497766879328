import { useFormContext } from "react-hook-form";

import { FormFieldWrapper } from "components/atoms/FormFieldWrapper";

import classnames from "lib/classnames";

const FIELD_NAME = "last_name";

export default function LastNameInput() {
  const formContext = useFormContext();

  const lastNameRegistration = formContext?.register(FIELD_NAME, {
    required: {
      value: true,
      message: "This field is required",
    },
  });

  const lastNameInputClassNames = classnames.textField(
    !!formContext.formState.errors[FIELD_NAME]
  );

  return (
    <FormFieldWrapper name={FIELD_NAME} label="Last Name">
      <input
        id={FIELD_NAME}
        type="text"
        className={lastNameInputClassNames}
        {...lastNameRegistration}
      />
    </FormFieldWrapper>
  );
}

import { RegisterOptions, useFormContext } from "react-hook-form";

interface Props {
  name: string;
  value?: string;
  required?: boolean;
  registerOptions?: RegisterOptions;
}

export default function HiddenInput({
  name,
  required,
  value,
  registerOptions = {},
}: Props) {
  const formContext = useFormContext();

  const hiddenInputRegistration = formContext?.register(name, {
    required: required && "This field is required",
    ...registerOptions,
  });

  return (
    <input type="hidden" id={name} value={value} {...hiddenInputRegistration} />
  );
}

import clsx from "clsx";
import { CONSENT } from "data/consent";

import { TextColors } from "types/theme.types";

export interface PolicyAgreementProps {
  color?: TextColors;
  size?: string;
  alignment?: string;
  mt?: string;
}

export default function PolicyAgreement(props: PolicyAgreementProps) {
  const contentClassName = clsx(
    "font-content",
    "leading-normal",
    "md:whitespace-pre-lin",
    props.alignment ?? "text-center",
    props.size ?? "text-xs",
    props.color ?? "text-medium-gray",
    props.mt ?? "mt-6"
  );

  return <p className={contentClassName}>{CONSENT}</p>;
}

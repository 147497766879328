import { useFormContext } from "react-hook-form";
import { string } from "yup";

import { FormFieldWrapper } from "components/atoms/FormFieldWrapper";

import classnames from "lib/classnames";
import validateWorkEmail from "lib/validations/validate-work-email";

const FIELD_NAME = "email";

const validationSchema = string().email().required();

type Props = {
  preventPersonalEmails?: boolean;
  label?: string;
};
export default function EmailInput(props: Props) {
  const formContext = useFormContext();

  const companyValidation = {
    companyEmail: (value) =>
      validateWorkEmail(value) || "Please enter your work email",
  };

  const emailRegistration = formContext?.register(FIELD_NAME, {
    required: {
      value: true,
      message: "This field is required",
    },
    validate: {
      validEmail: async (value) => {
        const fieldData = formContext.getFieldState(FIELD_NAME);
        if (!fieldData?.isTouched) return;

        return (
          (await validationSchema.isValid(value)) || "This is not a valid email"
        );
      },
      ...(props.preventPersonalEmails ? companyValidation : {}),
    },
  });

  const emailInputClassNames = classnames.textField(
    !!formContext.formState.errors[FIELD_NAME]
  );

  return (
    <FormFieldWrapper name={FIELD_NAME} label={props.label ?? "Email"}>
      <input
        id={FIELD_NAME}
        type="email"
        className={emailInputClassNames}
        {...emailRegistration}
      />
    </FormFieldWrapper>
  );
}
